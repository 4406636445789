import React, { useCallback, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Grid, TextField } from '@mui/material';
import { DialogSection, Nullable, TimePickerDialog } from '@linetweet/linetweet-ui';
import { DesktopTimePicker } from '@mui/x-date-pickers';
import { useIntl } from 'react-intl';

import { TimeHelper } from 'utils';

import styles from './AppointmentFormTimeSection.module.scss';

type Props = {
  isDisabled?: boolean;
  justFrom?: boolean;
};

function AppointmentFormTimeSectionComponent({ isDisabled, justFrom }: Props) {
  const intl = useIntl();

  const form = useFormContext();
  const [isTimePickerDialogOpen, setIsTimePickerDialogOpen] = useState<boolean>(false);
  const [timePickerValue, setTimePickerValue] = useState<Nullable<number>>(null);
  const [timePickerField, setTimePickerField] = useState<'from' | 'to'>('from');

  const dateValue = form.watch('date');

  const onTimePickerDialogOpen = useCallback((fieldName: 'from' | 'to', value: Nullable<number>) => {
    setTimePickerValue(value || null);
    setTimePickerField(fieldName);
    setIsTimePickerDialogOpen(true);
  }, []);

  const onTimePickerDialogSubmit = useCallback((fieldName: string, newTime: number) => {
    setIsTimePickerDialogOpen(false);
    form.setValue(fieldName as 'from' | 'to', newTime, { shouldValidate: true, shouldDirty: true });
    setTimePickerValue(null);
  }, []);

  const onTimePickerDialogClose = useCallback(() => {
    setIsTimePickerDialogOpen(false);
    setTimePickerValue(null);
  }, []);

  const fromValue = form.watch('from');

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <DialogSection title={intl.formatMessage({ id: 'common.from' })}>
            <Controller
              control={form.control}
              name="from"
              render={({ field, fieldState }) => (
                <DesktopTimePicker
                  value={field.value != null ? TimeHelper.dateTimeToDayjs(dateValue, field.value) : null}
                  disabled={!!isDisabled}
                  onChange={(value) => {
                    if (value == null) {
                      field.onChange(value);
                      return;
                    }

                    field.onChange(TimeHelper.getMinutesFromDayStart(value));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      disabled={!!isDisabled}
                      className={styles.timeInput}
                      onClick={() => {
                        onTimePickerDialogOpen(field.name, field.value);
                      }}
                      inputProps={{ ...params.inputProps, 'data-testid': 'form-time-section-from-input' }}
                      onBlur={field.onBlur}
                      variant="outlined"
                      required
                      error={!!fieldState.error}
                    />
                  )}
                />
              )}
            />
          </DialogSection>
        </Grid>

        {!justFrom && (
          <Grid item xs={6}>
            <DialogSection title={intl.formatMessage({ id: 'common.to' })}>
              <Controller
                control={form.control}
                name="to"
                render={({ field, fieldState }) => (
                  <DesktopTimePicker
                    minTime={fromValue != null ? TimeHelper.dateTimeToDayjs(dateValue, 8 * 60) : null}
                    value={field.value != null ? TimeHelper.dateTimeToDayjs(dateValue, field.value) : null}
                    disabled={!!isDisabled}
                    onChange={(value) => {
                      if (value == null) {
                        field.onChange(value);
                        return;
                      }

                      field.onChange(TimeHelper.getMinutesFromDayStart(value));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        disabled={!!isDisabled}
                        onClick={() => {
                          onTimePickerDialogOpen(field.name, field.value);
                        }}
                        inputProps={{ ...params.inputProps, 'data-testid': 'form-time-section-to-input' }}
                        onBlur={field.onBlur}
                        className={styles.timeInput}
                        required
                        error={!!fieldState.error}
                      />
                    )}
                  />
                )}
              />
            </DialogSection>
          </Grid>
        )}
      </Grid>
      <TimePickerDialog
        open={isTimePickerDialogOpen}
        initialValue={timePickerValue}
        fieldName={timePickerField}
        onSubmit={onTimePickerDialogSubmit}
        onClose={onTimePickerDialogClose}
      />
    </>
  );
}

AppointmentFormTimeSectionComponent.defaultProps = {
  isDisabled: false,
  justFrom: false,
};

export const AppointmentFormTimeSection = React.memo(AppointmentFormTimeSectionComponent);
