import * as yup from 'yup';

export const bossAppointmentFormSchema = yup.object().shape({
  date: yup
    .string()
    .required(),
  note: yup
    .string()
    .nullable()
    .defined(),
  gender: yup
    .string()
    .nullable()
    .defined(),
  services: yup
    .array()
    .required()
    .min(1),
  employee: yup
    .string()
    .defined()
    .nullable(),
  lastName: yup
    .string()
    .required(),
  firstName: yup
    .string()
    .required(),
  email: yup
    .string()
    .nullable()
    .email(),
  phoneNumber: yup
    .string()
    .required()
    .matches(/^[0-9]*$/),
  from: yup
    .number()
    .nullable()
    .required(),
});
