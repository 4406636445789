export enum CalendarEventTypeEnum {
  WALKIN = 'Walkin',
  APPOINTMENT = 'appointment',
  BOSS_APPOINTMENT = 'boss_appointment', // only on the webapp side
  RESERVATION = 'reservation',
  TASK = 'task',
  BREAK = 'break',
  BLOCKER = 'blocker',
  SLOT = 'slot',
  WORKING_HOURS = 'working_hours',
}
